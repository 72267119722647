import { CreateUserDtoUserTypeEnum } from '../services/api/user-api/api';

export const UserDropDownoptions = [
  { value: CreateUserDtoUserTypeEnum.Admin, name: 'Admin Health guide' },
  { value: CreateUserDtoUserTypeEnum.Coach, name: 'Health guide' },
  { value: CreateUserDtoUserTypeEnum.Physician, name: 'Physician' },
];

export const TABLE_DEFAULT_LIMIT = 10;

export const USER_SYSTEM_ID: string = '00000000-0000-0000-0000-000000000000';

export enum QueueTab {
  Queue = 'Queue',
  Recent = 'Recent',
  Starred = 'Starred',
  AllGroups = 'All Groups',
  PastPatients = 'Past Patients',
}
