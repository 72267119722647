/* tslint:disable */
/* eslint-disable */
/**
 * Sunrise Queue API
 * Queue management API.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccountDto
 */
export interface AccountDto {
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    'id': string;
    /**
     * 
     * @type {UserDto}
     * @memberof AccountDto
     */
    'owner': UserDto;
    /**
     * 
     * @type {Array<MembershipDto>}
     * @memberof AccountDto
     */
    'memberships': Array<MembershipDto>;
    /**
     * 
     * @type {Array<InvitationDto>}
     * @memberof AccountDto
     */
    'invitations': Array<InvitationDto>;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    'status'?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse200
     */
    'info'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse200
     */
    'error'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse200
     */
    'details'?: { [key: string]: { [key: string]: object; }; };
}
/**
 * 
 * @export
 * @interface InlineResponse503
 */
export interface InlineResponse503 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse503
     */
    'status'?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse503
     */
    'info'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse503
     */
    'error'?: { [key: string]: { [key: string]: object; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: object; }; }}
     * @memberof InlineResponse503
     */
    'details'?: { [key: string]: { [key: string]: object; }; };
}
/**
 * 
 * @export
 * @interface InvitationDto
 */
export interface InvitationDto {
    /**
     * 
     * @type {string}
     * @memberof InvitationDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof InvitationDto
     */
    'phoneCountryCode': string;
    /**
     * 
     * @type {string}
     * @memberof InvitationDto
     */
    'invitedMobileNumber': string;
    /**
     * 
     * @type {string}
     * @memberof InvitationDto
     */
    'status': string;
    /**
     * 
     * @type {UserDto}
     * @memberof InvitationDto
     */
    'owner': UserDto;
    /**
     * 
     * @type {string}
     * @memberof InvitationDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface MembershipDto
 */
export interface MembershipDto {
    /**
     * 
     * @type {string}
     * @memberof MembershipDto
     */
    'id': string;
    /**
     * 
     * @type {UserDto}
     * @memberof MembershipDto
     */
    'user': UserDto;
}
/**
 * 
 * @export
 * @interface Queue
 */
export interface Queue {
    /**
     * 
     * @type {string}
     * @memberof Queue
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Queue
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Queue
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Queue
     */
    'queueType': QueueQueueTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum QueueQueueTypeEnum {
    General = 'general',
    Scheduled = 'scheduled'
}

/**
 * 
 * @export
 * @interface QueueEntry
 */
export interface QueueEntry {
    /**
     * 
     * @type {string}
     * @memberof QueueEntry
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntry
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntry
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntry
     */
    'deletedAt': string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntry
     */
    'accountId': string;
    /**
     * 
     * @type {object}
     * @memberof QueueEntry
     */
    'coachId': object;
    /**
     * 
     * @type {object}
     * @memberof QueueEntry
     */
    'previousCoachId': object;
    /**
     * 
     * @type {string}
     * @memberof QueueEntry
     */
    'scheduledForStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntry
     */
    'scheduledForEnd'?: string;
    /**
     * 
     * @type {object}
     * @memberof QueueEntry
     */
    'physicianId'?: object;
    /**
     * 
     * @type {string}
     * @memberof QueueEntry
     */
    'timezone'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntry
     */
    'appointmentType'?: QueueEntryAppointmentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof QueueEntry
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntry
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntry
     */
    'status': QueueEntryStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof QueueEntry
     */
    'lastMessage': string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntry
     */
    'lastMessageTimestamp': string;
    /**
     * 
     * @type {UserDto}
     * @memberof QueueEntry
     */
    'lastMessageUser': UserDto;
    /**
     * 
     * @type {object}
     * @memberof QueueEntry
     */
    'lastCoachSeen': object;
    /**
     * 
     * @type {string}
     * @memberof QueueEntry
     */
    'createdBy': string;
}

/**
    * @export
    * @enum {string}
    */
export enum QueueEntryAppointmentTypeEnum {
    CheckIn = 'check_in',
    Physician = 'physician',
    Coach = 'coach'
}
/**
    * @export
    * @enum {string}
    */
export enum QueueEntryStatusEnum {
    Pending = 'pending',
    Scheduled = 'scheduled',
    Assigned = 'assigned',
    InProgress = 'in_progress',
    Completed = 'completed'
}

/**
 * 
 * @export
 * @interface QueueEntryCreateDto
 */
export interface QueueEntryCreateDto {
    /**
     * 
     * @type {string}
     * @memberof QueueEntryCreateDto
     */
    'scheduledForStart': string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryCreateDto
     */
    'scheduledForEnd': string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryCreateDto
     */
    'accountId': string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryCreateDto
     */
    'timezone': string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryCreateDto
     */
    'appointmentType': QueueEntryCreateDtoAppointmentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryCreateDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryCreateDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryCreateDto
     */
    'coachId'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryCreateDto
     */
    'physicianId'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum QueueEntryCreateDtoAppointmentTypeEnum {
    CheckIn = 'check_in',
    Physician = 'physician',
    Coach = 'coach'
}

/**
 * 
 * @export
 * @interface QueueEntryDto
 */
export interface QueueEntryDto {
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {Queue}
     * @memberof QueueEntryDto
     */
    'queue': Queue;
    /**
     * 
     * @type {AccountDto}
     * @memberof QueueEntryDto
     */
    'account'?: AccountDto;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'coachId'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'previousCoachId'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'status': QueueEntryDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'lastMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'lastMessageTimestamp'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof QueueEntryDto
     */
    'lastMessageUser'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'scheduledForStart': string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'scheduledForEnd': string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'timezone': string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'appointmentType': QueueEntryDtoAppointmentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'physicianId'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueEntryDto
     */
    'createdBy'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum QueueEntryDtoStatusEnum {
    Pending = 'pending',
    Scheduled = 'scheduled',
    Assigned = 'assigned',
    InProgress = 'in_progress',
    Archived = 'archived'
}
/**
    * @export
    * @enum {string}
    */
export enum QueueEntryDtoAppointmentTypeEnum {
    CheckIn = 'check_in',
    Physician = 'physician',
    Coach = 'coach'
}

/**
 * 
 * @export
 * @interface QueueEntryPaginatedResultDto
 */
export interface QueueEntryPaginatedResultDto {
    /**
     * 
     * @type {number}
     * @memberof QueueEntryPaginatedResultDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof QueueEntryPaginatedResultDto
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof QueueEntryPaginatedResultDto
     */
    'offset': number;
    /**
     * 
     * @type {Array<QueueEntryDto>}
     * @memberof QueueEntryPaginatedResultDto
     */
    'results': Array<QueueEntryDto>;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'userType': UserDtoUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'phone': string;
}

/**
    * @export
    * @enum {string}
    */
export enum UserDtoUserTypeEnum {
    Admin = 'admin',
    Customer = 'customer',
    Physician = 'physician',
    Coach = 'coach',
    Assistant = 'assistant'
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheck: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerCheck(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerCheck(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheck(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.healthControllerCheck(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthControllerCheck(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).healthControllerCheck(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QueueApi - axios parameter creator
 * @export
 */
export const QueueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueControllerCreateForAccount: async (accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('queueControllerCreateForAccount', 'accountId', accountId)
            const localVarPath = `/v1/queue/general/queue-entry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {QueueEntryCreateDto} queueEntryCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueControllerCreateQueueEntry: async (queueEntryCreateDto: QueueEntryCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'queueEntryCreateDto' is not null or undefined
            assertParamExists('queueControllerCreateQueueEntry', 'queueEntryCreateDto', queueEntryCreateDto)
            const localVarPath = `/v1/queue/scheduled/queue-entry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queueEntryCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/queue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('queueControllerRemove', 'id', id)
            const localVarPath = `/v1/queue/scheduled/queue-entry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {QueueEntryCreateDto} queueEntryCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueControllerUpdate: async (id: string, queueEntryCreateDto: QueueEntryCreateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('queueControllerUpdate', 'id', id)
            // verify required parameter 'queueEntryCreateDto' is not null or undefined
            assertParamExists('queueControllerUpdate', 'queueEntryCreateDto', queueEntryCreateDto)
            const localVarPath = `/v1/queue/scheduled/queue-entry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(queueEntryCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerAssign: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('queueEntryControllerAssign', 'id', id)
            const localVarPath = `/v1/queue-entry/{id}/assign`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerComplete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('queueEntryControllerComplete', 'id', id)
            const localVarPath = `/v1/queue-entry/{id}/complete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>} [status] 
         * @param {string} [updatedAfter] 
         * @param {string} [updatedBefore] 
         * @param {string} [scheduledForStartAfter] 
         * @param {string} [scheduledForStartBefore] 
         * @param {'general' | 'scheduled'} [queueType] 
         * @param {string} [coachId] 
         * @param {string} [physicianId] 
         * @param {string} [lastMessageTimestamp] 
         * @param {string} [accountId] 
         * @param {boolean} [hasNoCoach] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerFindAll: async (limit: number, offset: number, order?: string, status?: Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>, updatedAfter?: string, updatedBefore?: string, scheduledForStartAfter?: string, scheduledForStartBefore?: string, queueType?: 'general' | 'scheduled', coachId?: string, physicianId?: string, lastMessageTimestamp?: string, accountId?: string, hasNoCoach?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('queueEntryControllerFindAll', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('queueEntryControllerFindAll', 'offset', offset)
            const localVarPath = `/v1/queue-entry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (updatedAfter !== undefined) {
                localVarQueryParameter['updatedAfter'] = (updatedAfter as any instanceof Date) ?
                    (updatedAfter as any).toISOString() :
                    updatedAfter;
            }

            if (updatedBefore !== undefined) {
                localVarQueryParameter['updatedBefore'] = (updatedBefore as any instanceof Date) ?
                    (updatedBefore as any).toISOString() :
                    updatedBefore;
            }

            if (scheduledForStartAfter !== undefined) {
                localVarQueryParameter['scheduledForStartAfter'] = (scheduledForStartAfter as any instanceof Date) ?
                    (scheduledForStartAfter as any).toISOString() :
                    scheduledForStartAfter;
            }

            if (scheduledForStartBefore !== undefined) {
                localVarQueryParameter['scheduledForStartBefore'] = (scheduledForStartBefore as any instanceof Date) ?
                    (scheduledForStartBefore as any).toISOString() :
                    scheduledForStartBefore;
            }

            if (queueType !== undefined) {
                localVarQueryParameter['queueType'] = queueType;
            }

            if (coachId !== undefined) {
                localVarQueryParameter['coachId'] = coachId;
            }

            if (physicianId !== undefined) {
                localVarQueryParameter['physicianId'] = physicianId;
            }

            if (lastMessageTimestamp !== undefined) {
                localVarQueryParameter['lastMessageTimestamp'] = (lastMessageTimestamp as any instanceof Date) ?
                    (lastMessageTimestamp as any).toISOString() :
                    lastMessageTimestamp;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (hasNoCoach !== undefined) {
                localVarQueryParameter['hasNoCoach'] = hasNoCoach;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>} [status] 
         * @param {string} [updatedAfter] 
         * @param {string} [updatedBefore] 
         * @param {string} [scheduledForStartAfter] 
         * @param {string} [scheduledForStartBefore] 
         * @param {'general' | 'scheduled'} [queueType] 
         * @param {string} [coachId] 
         * @param {string} [physicianId] 
         * @param {string} [lastMessageTimestamp] 
         * @param {string} [accountId] 
         * @param {boolean} [hasNoCoach] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerFindAllScheduled: async (limit: number, offset: number, order?: string, status?: Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>, updatedAfter?: string, updatedBefore?: string, scheduledForStartAfter?: string, scheduledForStartBefore?: string, queueType?: 'general' | 'scheduled', coachId?: string, physicianId?: string, lastMessageTimestamp?: string, accountId?: string, hasNoCoach?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('queueEntryControllerFindAllScheduled', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('queueEntryControllerFindAllScheduled', 'offset', offset)
            const localVarPath = `/v1/queue-entry/scheduled`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (updatedAfter !== undefined) {
                localVarQueryParameter['updatedAfter'] = (updatedAfter as any instanceof Date) ?
                    (updatedAfter as any).toISOString() :
                    updatedAfter;
            }

            if (updatedBefore !== undefined) {
                localVarQueryParameter['updatedBefore'] = (updatedBefore as any instanceof Date) ?
                    (updatedBefore as any).toISOString() :
                    updatedBefore;
            }

            if (scheduledForStartAfter !== undefined) {
                localVarQueryParameter['scheduledForStartAfter'] = (scheduledForStartAfter as any instanceof Date) ?
                    (scheduledForStartAfter as any).toISOString() :
                    scheduledForStartAfter;
            }

            if (scheduledForStartBefore !== undefined) {
                localVarQueryParameter['scheduledForStartBefore'] = (scheduledForStartBefore as any instanceof Date) ?
                    (scheduledForStartBefore as any).toISOString() :
                    scheduledForStartBefore;
            }

            if (queueType !== undefined) {
                localVarQueryParameter['queueType'] = queueType;
            }

            if (coachId !== undefined) {
                localVarQueryParameter['coachId'] = coachId;
            }

            if (physicianId !== undefined) {
                localVarQueryParameter['physicianId'] = physicianId;
            }

            if (lastMessageTimestamp !== undefined) {
                localVarQueryParameter['lastMessageTimestamp'] = (lastMessageTimestamp as any instanceof Date) ?
                    (lastMessageTimestamp as any).toISOString() :
                    lastMessageTimestamp;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (hasNoCoach !== undefined) {
                localVarQueryParameter['hasNoCoach'] = hasNoCoach;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('queueEntryControllerFindOne', 'id', id)
            const localVarPath = `/v1/queue-entry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerFindRecent: async (limit: number, offset: number, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('queueEntryControllerFindRecent', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('queueEntryControllerFindRecent', 'offset', offset)
            const localVarPath = `/v1/queue-entry/past-scheduled-patients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerUnassign: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('queueEntryControllerUnassign', 'id', id)
            const localVarPath = `/v1/queue-entry/{id}/unassign`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QueueApi - functional programming interface
 * @export
 */
export const QueueApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QueueApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueControllerCreateForAccount(accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueControllerCreateForAccount(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {QueueEntryCreateDto} queueEntryCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueControllerCreateQueueEntry(queueEntryCreateDto: QueueEntryCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueControllerCreateQueueEntry(queueEntryCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueControllerFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueControllerRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {QueueEntryCreateDto} queueEntryCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueControllerUpdate(id: string, queueEntryCreateDto: QueueEntryCreateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueControllerUpdate(id, queueEntryCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueEntryControllerAssign(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueEntryControllerAssign(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueEntryControllerComplete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueEntryControllerComplete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>} [status] 
         * @param {string} [updatedAfter] 
         * @param {string} [updatedBefore] 
         * @param {string} [scheduledForStartAfter] 
         * @param {string} [scheduledForStartBefore] 
         * @param {'general' | 'scheduled'} [queueType] 
         * @param {string} [coachId] 
         * @param {string} [physicianId] 
         * @param {string} [lastMessageTimestamp] 
         * @param {string} [accountId] 
         * @param {boolean} [hasNoCoach] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueEntryControllerFindAll(limit: number, offset: number, order?: string, status?: Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>, updatedAfter?: string, updatedBefore?: string, scheduledForStartAfter?: string, scheduledForStartBefore?: string, queueType?: 'general' | 'scheduled', coachId?: string, physicianId?: string, lastMessageTimestamp?: string, accountId?: string, hasNoCoach?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueEntryPaginatedResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueEntryControllerFindAll(limit, offset, order, status, updatedAfter, updatedBefore, scheduledForStartAfter, scheduledForStartBefore, queueType, coachId, physicianId, lastMessageTimestamp, accountId, hasNoCoach, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>} [status] 
         * @param {string} [updatedAfter] 
         * @param {string} [updatedBefore] 
         * @param {string} [scheduledForStartAfter] 
         * @param {string} [scheduledForStartBefore] 
         * @param {'general' | 'scheduled'} [queueType] 
         * @param {string} [coachId] 
         * @param {string} [physicianId] 
         * @param {string} [lastMessageTimestamp] 
         * @param {string} [accountId] 
         * @param {boolean} [hasNoCoach] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueEntryControllerFindAllScheduled(limit: number, offset: number, order?: string, status?: Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>, updatedAfter?: string, updatedBefore?: string, scheduledForStartAfter?: string, scheduledForStartBefore?: string, queueType?: 'general' | 'scheduled', coachId?: string, physicianId?: string, lastMessageTimestamp?: string, accountId?: string, hasNoCoach?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueEntryPaginatedResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueEntryControllerFindAllScheduled(limit, offset, order, status, updatedAfter, updatedBefore, scheduledForStartAfter, scheduledForStartBefore, queueType, coachId, physicianId, lastMessageTimestamp, accountId, hasNoCoach, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueEntryControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueEntryControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueEntryControllerFindRecent(limit: number, offset: number, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueEntryPaginatedResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueEntryControllerFindRecent(limit, offset, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueEntryControllerUnassign(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueEntryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueEntryControllerUnassign(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QueueApi - factory interface
 * @export
 */
export const QueueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QueueApiFp(configuration)
    return {
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueControllerCreateForAccount(accountId: string, options?: any): AxiosPromise<QueueEntryDto> {
            return localVarFp.queueControllerCreateForAccount(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {QueueEntryCreateDto} queueEntryCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueControllerCreateQueueEntry(queueEntryCreateDto: QueueEntryCreateDto, options?: any): AxiosPromise<QueueEntryDto> {
            return localVarFp.queueControllerCreateQueueEntry(queueEntryCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueControllerFindAll(options?: any): AxiosPromise<void> {
            return localVarFp.queueControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueControllerRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.queueControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {QueueEntryCreateDto} queueEntryCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueControllerUpdate(id: string, queueEntryCreateDto: QueueEntryCreateDto, options?: any): AxiosPromise<QueueEntry> {
            return localVarFp.queueControllerUpdate(id, queueEntryCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerAssign(id: string, options?: any): AxiosPromise<QueueEntryDto> {
            return localVarFp.queueEntryControllerAssign(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerComplete(id: string, options?: any): AxiosPromise<QueueEntryDto> {
            return localVarFp.queueEntryControllerComplete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>} [status] 
         * @param {string} [updatedAfter] 
         * @param {string} [updatedBefore] 
         * @param {string} [scheduledForStartAfter] 
         * @param {string} [scheduledForStartBefore] 
         * @param {'general' | 'scheduled'} [queueType] 
         * @param {string} [coachId] 
         * @param {string} [physicianId] 
         * @param {string} [lastMessageTimestamp] 
         * @param {string} [accountId] 
         * @param {boolean} [hasNoCoach] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerFindAll(limit: number, offset: number, order?: string, status?: Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>, updatedAfter?: string, updatedBefore?: string, scheduledForStartAfter?: string, scheduledForStartBefore?: string, queueType?: 'general' | 'scheduled', coachId?: string, physicianId?: string, lastMessageTimestamp?: string, accountId?: string, hasNoCoach?: boolean, options?: any): AxiosPromise<QueueEntryPaginatedResultDto> {
            return localVarFp.queueEntryControllerFindAll(limit, offset, order, status, updatedAfter, updatedBefore, scheduledForStartAfter, scheduledForStartBefore, queueType, coachId, physicianId, lastMessageTimestamp, accountId, hasNoCoach, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>} [status] 
         * @param {string} [updatedAfter] 
         * @param {string} [updatedBefore] 
         * @param {string} [scheduledForStartAfter] 
         * @param {string} [scheduledForStartBefore] 
         * @param {'general' | 'scheduled'} [queueType] 
         * @param {string} [coachId] 
         * @param {string} [physicianId] 
         * @param {string} [lastMessageTimestamp] 
         * @param {string} [accountId] 
         * @param {boolean} [hasNoCoach] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerFindAllScheduled(limit: number, offset: number, order?: string, status?: Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>, updatedAfter?: string, updatedBefore?: string, scheduledForStartAfter?: string, scheduledForStartBefore?: string, queueType?: 'general' | 'scheduled', coachId?: string, physicianId?: string, lastMessageTimestamp?: string, accountId?: string, hasNoCoach?: boolean, options?: any): AxiosPromise<QueueEntryPaginatedResultDto> {
            return localVarFp.queueEntryControllerFindAllScheduled(limit, offset, order, status, updatedAfter, updatedBefore, scheduledForStartAfter, scheduledForStartBefore, queueType, coachId, physicianId, lastMessageTimestamp, accountId, hasNoCoach, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerFindOne(id: string, options?: any): AxiosPromise<QueueEntryDto> {
            return localVarFp.queueEntryControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerFindRecent(limit: number, offset: number, order?: string, options?: any): AxiosPromise<QueueEntryPaginatedResultDto> {
            return localVarFp.queueEntryControllerFindRecent(limit, offset, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryControllerUnassign(id: string, options?: any): AxiosPromise<QueueEntryDto> {
            return localVarFp.queueEntryControllerUnassign(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QueueApi - object-oriented interface
 * @export
 * @class QueueApi
 * @extends {BaseAPI}
 */
export class QueueApi extends BaseAPI {
    /**
     * 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public queueControllerCreateForAccount(accountId: string, options?: AxiosRequestConfig) {
        return QueueApiFp(this.configuration).queueControllerCreateForAccount(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {QueueEntryCreateDto} queueEntryCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public queueControllerCreateQueueEntry(queueEntryCreateDto: QueueEntryCreateDto, options?: AxiosRequestConfig) {
        return QueueApiFp(this.configuration).queueControllerCreateQueueEntry(queueEntryCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public queueControllerFindAll(options?: AxiosRequestConfig) {
        return QueueApiFp(this.configuration).queueControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public queueControllerRemove(id: string, options?: AxiosRequestConfig) {
        return QueueApiFp(this.configuration).queueControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {QueueEntryCreateDto} queueEntryCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public queueControllerUpdate(id: string, queueEntryCreateDto: QueueEntryCreateDto, options?: AxiosRequestConfig) {
        return QueueApiFp(this.configuration).queueControllerUpdate(id, queueEntryCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public queueEntryControllerAssign(id: string, options?: AxiosRequestConfig) {
        return QueueApiFp(this.configuration).queueEntryControllerAssign(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public queueEntryControllerComplete(id: string, options?: AxiosRequestConfig) {
        return QueueApiFp(this.configuration).queueEntryControllerComplete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {number} offset 
     * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
     * @param {Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>} [status] 
     * @param {string} [updatedAfter] 
     * @param {string} [updatedBefore] 
     * @param {string} [scheduledForStartAfter] 
     * @param {string} [scheduledForStartBefore] 
     * @param {'general' | 'scheduled'} [queueType] 
     * @param {string} [coachId] 
     * @param {string} [physicianId] 
     * @param {string} [lastMessageTimestamp] 
     * @param {string} [accountId] 
     * @param {boolean} [hasNoCoach] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public queueEntryControllerFindAll(limit: number, offset: number, order?: string, status?: Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>, updatedAfter?: string, updatedBefore?: string, scheduledForStartAfter?: string, scheduledForStartBefore?: string, queueType?: 'general' | 'scheduled', coachId?: string, physicianId?: string, lastMessageTimestamp?: string, accountId?: string, hasNoCoach?: boolean, options?: AxiosRequestConfig) {
        return QueueApiFp(this.configuration).queueEntryControllerFindAll(limit, offset, order, status, updatedAfter, updatedBefore, scheduledForStartAfter, scheduledForStartBefore, queueType, coachId, physicianId, lastMessageTimestamp, accountId, hasNoCoach, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {number} offset 
     * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
     * @param {Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>} [status] 
     * @param {string} [updatedAfter] 
     * @param {string} [updatedBefore] 
     * @param {string} [scheduledForStartAfter] 
     * @param {string} [scheduledForStartBefore] 
     * @param {'general' | 'scheduled'} [queueType] 
     * @param {string} [coachId] 
     * @param {string} [physicianId] 
     * @param {string} [lastMessageTimestamp] 
     * @param {string} [accountId] 
     * @param {boolean} [hasNoCoach] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public queueEntryControllerFindAllScheduled(limit: number, offset: number, order?: string, status?: Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>, updatedAfter?: string, updatedBefore?: string, scheduledForStartAfter?: string, scheduledForStartBefore?: string, queueType?: 'general' | 'scheduled', coachId?: string, physicianId?: string, lastMessageTimestamp?: string, accountId?: string, hasNoCoach?: boolean, options?: AxiosRequestConfig) {
        return QueueApiFp(this.configuration).queueEntryControllerFindAllScheduled(limit, offset, order, status, updatedAfter, updatedBefore, scheduledForStartAfter, scheduledForStartBefore, queueType, coachId, physicianId, lastMessageTimestamp, accountId, hasNoCoach, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public queueEntryControllerFindOne(id: string, options?: AxiosRequestConfig) {
        return QueueApiFp(this.configuration).queueEntryControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {number} offset 
     * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public queueEntryControllerFindRecent(limit: number, offset: number, order?: string, options?: AxiosRequestConfig) {
        return QueueApiFp(this.configuration).queueEntryControllerFindRecent(limit, offset, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueApi
     */
    public queueEntryControllerUnassign(id: string, options?: AxiosRequestConfig) {
        return QueueApiFp(this.configuration).queueEntryControllerUnassign(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QueueEntryHistoryApi - axios parameter creator
 * @export
 */
export const QueueEntryHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>} status 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryHistoryControllerFindAll: async (limit: number, offset: number, status: Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>, order?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('queueEntryHistoryControllerFindAll', 'limit', limit)
            // verify required parameter 'offset' is not null or undefined
            assertParamExists('queueEntryHistoryControllerFindAll', 'offset', offset)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('queueEntryHistoryControllerFindAll', 'status', status)
            const localVarPath = `/v1/queue-entry-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QueueEntryHistoryApi - functional programming interface
 * @export
 */
export const QueueEntryHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QueueEntryHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>} status 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueEntryHistoryControllerFindAll(limit: number, offset: number, status: Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>, order?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueEntryPaginatedResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queueEntryHistoryControllerFindAll(limit, offset, status, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QueueEntryHistoryApi - factory interface
 * @export
 */
export const QueueEntryHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QueueEntryHistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {number} limit 
         * @param {number} offset 
         * @param {Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>} status 
         * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueEntryHistoryControllerFindAll(limit: number, offset: number, status: Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>, order?: string, options?: any): AxiosPromise<QueueEntryPaginatedResultDto> {
            return localVarFp.queueEntryHistoryControllerFindAll(limit, offset, status, order, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QueueEntryHistoryApi - object-oriented interface
 * @export
 * @class QueueEntryHistoryApi
 * @extends {BaseAPI}
 */
export class QueueEntryHistoryApi extends BaseAPI {
    /**
     * 
     * @param {number} limit 
     * @param {number} offset 
     * @param {Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>} status 
     * @param {string} [order] format: \&quot;(+|-)&lt;column_name&gt;,...\&quot; eg: \&quot;+firstName,-lastName\&quot;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QueueEntryHistoryApi
     */
    public queueEntryHistoryControllerFindAll(limit: number, offset: number, status: Array<'pending' | 'scheduled' | 'assigned' | 'in_progress' | 'completed'>, order?: string, options?: AxiosRequestConfig) {
        return QueueEntryHistoryApiFp(this.configuration).queueEntryHistoryControllerFindAll(limit, offset, status, order, options).then((request) => request(this.axios, this.basePath));
    }
}


