const theme = {
  colors: {
    default: '#272727',
    green: '#4DD395',
    primary: '#5885FF',
    lightGreen: '#F6FDF9',
    success: '#6AE058',
    warning: '#F1B534',
    error: '#E33F36',
    yellow: '#FED98F',
    red: '#FF756D',
    grayishWhite: '#F5F5F5',
    white: '#FFFFFF',
    black: '#000000',
    lightBlack: '#242731',
    lightGrey: '#DEDEDE',
    grey: '#7E7E7E',
    purple: '#B77BEF',
    orange: '#FF8644',
    darkPurple: '#776DFF',
    labelGrey: '#9EA0A9',
    chat: {
      grey: '#EDEDED',
      blue: '#DDEFFF',
      green: '#DEF8EC',
      purple: '#F3EBFF',
    },
    healthStory: {
      progressBar: 'rgb(90, 91, 113)',
      buttons: 'rgba(108,109,132,.9)',
      masks: {
        solid: {
          green: ['#30A973', '#3BBB92'],
          pink: ['#FF5EE8', '#C5D2FF'],
          purple: ['#A450FF', '#C5D2FF'],
          red: ['#FF6767', '#FFEAEA'],
          blue: ['#3068FF', '#C5ECFF'],
        },
        fading: {
          green: ['#30A973FF', '#3BBB9200'],
          pink: ['#FF5EE8FF', '#C5D2FF00'],
          purple: ['#A450FFFF', '#C5D2FF00'],
          red: ['#FF6767FF', '#FFEAEA00'],
          blue: ['#3068FFFF', '#C5ECFF00'],
        },
      },
    },
  },
  border: {
    colors: {
      active: '#4DD395',
      regular: '#272727',
      disabled: '#272727',
      warning: '#E09B35',
      error: '#E33F36',
    },
    radius: {
      default: '1rem',
      small: '0.8rem',
    },
  },
  layers: {
    default: 1,
    base: 10,
    overlay: 20,
    raised: 30,
    above: 40,
    onTop: 50,
  },
  fonts: {
    family: {
      default: "'Inter', sans-serif",
      secundary: "'Montserrat', sans-serif",
    },
    weight: {
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
      extraBold: 800,
    },
    sizes: {
      xxsmall: '0.8rem',
      xsmall: '1.2rem',
      small: '1.6rem',
      medium: '2.4rem',
      large: '3.2rem',
      xlarge: '4.0rem',
      xxlarge: '4.8rem',
      huge: '5.6rem',
      xhuge: '6.4rem',
    },
    spacings: {
      xsmall: '0.8rem',
      small: '1.6rem',
      medium: '2.4rem',
      large: '3.2rem',
      xlarge: '4.0rem',
      xxlarge: '4.8rem',
      huge: '5.6rem',
      xhuge: '6.4rem',
    },
    transitions: {
      default: '0.3s ease-in-out',
      quick: '0.1s ease-in-out',
    },
  },
} as const;

export type Theme = typeof theme;

export default theme;
