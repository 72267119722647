import { useCallback } from 'react';
import mixpanel from 'mixpanel-browser';
import useAppSelector from './useAppSelector';
import { UserDtoUserTypeEnum } from '../services/api/queue-api';

export enum Events {
  UserOpensAChat = 'Opens a chat',
  UserClosesAChat = 'Closes a chat',
  UserAssignsAScheduled = 'Assigns a scheduled appointment for themselves',
  UserReturnsAChat = 'Returns a chat to the queue',
  UserStartsTypingMessage = 'Starts typing message',
  UserSendsMessage = 'Sends message',
  UserSchedulesAnAppt = 'Schedules an appt',
  UserEditsAnAppt = 'Edits an appt',
  UserStartsACall = 'Starts a call',
  UserEndsACall = 'Ends a call',
  UserBeginsCheckIn = 'Begins check-in',
  UserUploadsACard = 'Uploads a health journey card',
  UserViewsHealthStory = 'User views a story',
  UserAddedToGroup = 'User added to group',
  UserWasDeniedAccess = 'User was denied access',
  UserRemovedFromGroup = 'User removed from group',
}

function useTrackEvent(eventName: Events) {
  const currentUser = useAppSelector(({ me }) => me);

  const currentUserType = () => {
    const UserType: Record<UserDtoUserTypeEnum | string, string> = {
      [UserDtoUserTypeEnum.Admin]: 'Guide',
      [UserDtoUserTypeEnum.Coach]: 'Health Guide',
      [UserDtoUserTypeEnum.Physician]: 'Physician',
    };
    return UserType[currentUser.userType!];
  };

  mixpanel.identify(currentUser.id!);
  mixpanel.register({ 'User type': currentUserType() });

  return useCallback(
    (properties?: object) => {
      try {
        mixpanel.track(eventName, properties);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    [eventName],
  );
}

export default useTrackEvent;
