/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../api/user-api';

type Nullable<T> = { [K in keyof T]: T[K] | null };

const initialState: Nullable<User> = {
  id: null,
  userType: null,
  createdAt: null,
  updatedAt: null,
  firstName: null,
  lastName: null,
  phone: null,
  email: null,
  tosAcceptedAt: null,
  tosVersion: null,
  isCaregiver: null,
  phoneCountryCode: null,
  password: null,
  account: null,
  memberships: null,
  birthday: null,
  deletionScheduledFor: null,
  isTestUser: null,
};

const meSlice = createSlice({
  name: 'me-slice',
  initialState,
  reducers: {
    setMe(state, action: PayloadAction<User>) {
      state.id = action.payload.id;
      state.userType = action.payload.userType;
      state.createdAt = action.payload.createdAt;
      state.updatedAt = action.payload.updatedAt;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.phone = action.payload.phone;
      state.email = action.payload.email;
      state.tosAcceptedAt = action.payload.tosAcceptedAt;
      state.tosVersion = action.payload.tosVersion;
      state.isCaregiver = action.payload.isCaregiver;
      state.account = action.payload.account;
      state.memberships = action.payload.memberships;
      state.phoneCountryCode = action.payload.phoneCountryCode;
      state.birthday = action.payload.birthday;
      state.password = action.payload.password;
      state.deletionScheduledFor = action.payload.deletionScheduledFor;
    },
    clearMe(state) {
      state.id = null;
      state.userType = null;
      state.createdAt = null;
      state.updatedAt = null;
      state.firstName = null;
      state.lastName = null;
      state.phone = null;
      state.email = null;
      state.tosAcceptedAt = null;
      state.tosVersion = null;
      state.isCaregiver = null;
      state.account = null;
      state.memberships = null;
      state.phoneCountryCode = null;
      state.birthday = null;
      state.password = null;
      state.deletionScheduledFor = null;
    },
  },
});

export const { setMe, clearMe } = meSlice.actions;

export default meSlice.reducer;
